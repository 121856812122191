import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeSJ from '../views/view-iphone/index.vue' //手机端


import HomePC from '../views/view-PC/HomeView.vue' //pc端

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [

  {
    path: '/',
    redirect: '/pc_home',
  },
  {
    path: '/pc_home', //首页
    name: 'HomePC',
    // component: HomePC, //pc端
    component: () => import('../views/view-PC/HomeView.vue')
  },
  {
    path: '/company', //公司简介
    name: 'companyProfile',
    component: () => import('../views/view-PC/companyProfile.vue')
  },
  {
    path: '/serve', //企业服务
    name: 'serve',
    component: () => import('../views/view-PC/serve.vue')
  },
  {
    path: '/news', //新闻资讯
    name: 'news',
    component: () => import('../views/view-PC/news.vue')
  },
  {
    path: '/shijun', //实钧智工
    name: "shijun",
    component: () => import('../views/view-PC/shijun.vue'),
  },
  {
    path: '/intro',
    redirect: '/shijun'
  },
  {
    path: '/pracing',
    name: 'shijunPraceView',
    component: () => import('@/views/view-PC/shijunPracing.vue')
  },
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import('@/views/view-PC/newsDetail.vue')
  },

  /* 手机端 首页*/
  {
    path: '/sj_home',
    name: 'HomeSJ',
    component: HomeSJ,
  },
  {
    path: '/navIndex', //跳转到导航
    name: 'navIndexView',
    component: () => import('../views/view-iphone/navIndex.vue')
  },
  {
    path: '/sj_company', //跳转到公司简介
    name: 'company',
    component: () => import('../views/view-iphone/company/company.vue')
  },
  {
    path: '/sj_serve',
    name: 'serve',
    component: () => import('../views/view-iphone/serve/serve.vue')
  },
  {
    path: '/sj_shijun',
    name: 'shijunzg',
    component: () => import('../views/view-iphone/shijunzg/shijunzg.vue')
  },
  {
    path: '/sj_news',
    name: 'news',
    component: () => import('../views/view-iphone/news/news.vue')
  },
  {
    path: '/shijunpracing',
    name: 'shijunpracing',
    component: () => import('../views/view-iphone/shijunpracing/shijunpracing_i.vue')
  },
  {
    path: '/newsdetailphone',
    name: 'newsdetailphone',
    component: () => import('../views/view-iphone/news/newsdetail.vue')
  }



]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})
//在 router/index.js 增加
router.beforeEach((to, from, next) => {
  if (to.path == '/pc_index') {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      router.push({
        path: '/sj_home'
      });
    }
  }
  next();
})
export default router