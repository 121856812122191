<template>
  <div>
    <div>
      <headerView />
      <div class="bgi">
        <swiperView />
      </div>
      <div class="four">
        <img src="../../static/sjImage/shouye/组合 525.svg" alt="">
        <img src="../../static/sjImage/shouye/组合 526.svg" alt="">
        <img src="../../static/sjImage/shouye/组合 528.svg" alt="">
        <div class="img">
          <img class="imglogo" src="../../static/sjImage/shouye/组合 531.svg" alt="">
          <img class="imgbg" src="../../static/sjImage/shouye/组合 529.svg" alt="">
        </div>
      </div>
      <div class="produce">
        <img src="../../static/sjImage/shouye/我们的服务.svg" alt="">
      </div>
      <ul class="fourr">
        <li>
          <img src="../../static/sjImage/shouye/组合 538.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 537.svg" alt="">
        </li>
        <li>
          <img class="wx" src="../../static/sjImage/shouye/微信.svg" alt="">
          <img src="../../static/sjImage/shouye//组合 540.svg" alt="">
        </li>
        <li>
          <img class="shebei" src="../../static/sjImage/shouye/设备.svg" alt="">
          <img src="../../static/sjImage/shouye/组合 539.svg" alt="">
        </li>
      </ul>
      <!-- 立即咨询 -->
      <div class="consultt" @click="showlxwm()">
        <img src="../../static/sjImage/shouye/组合 541.svg" alt="">
      </div>
      <!-- 自营产品 -->
      <div class="produce">
        <img src="../../static/sjImage/shouye/ziyanchanpin.svg" alt="">
      </div>
      <h5 class="mangementSystem">移动端后台管理系统</h5>
      <p class="txt">
        专为企业研发的一款信息化管理软件，采用最新的云端技术，将企业员工都纳入到标准化的管理流程中，实现信息数据互通互联。该系统通过手机端、微信端无缝对接，形成管理上的闭环，从而简化管理流程，缩短响应时间、降低运营成本、提升整体工作效率及品质。系统主要适用于工程、家装、软装、园林等建筑工程相关领域。
      </p>
      <div class="consultt">
        <img src="../../static/sjImage/shouye/组合 553.svg" alt="">
      </div>
      <!-- 轮播图 -->
      <div class="wrap">
        <ul class="containerr">
          <li>
            <div class="container">
              <div class="containerBox">
                <div class="swiper-container contain">
                  <div class="swiper-wrapper wrap">
                    <div class="swiper-slide item" v-for="(item, index) in pointImgList1" :key="index">
                      <img src="../../static/sjImage/shouye/图层 1 拷贝.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </li>
        </ul>
        <div class="btn">
          <!--  @touchend="changeIndex(1)" -->
          <!-- 上一张 -->
          <div class="swiper-button-prev lefts" @touchstart.prevent="changeIndex(1)"
            @touchend.native.prevent="changeIndexBack(1)">
          </div>
          <div class="swiper-button-next rights" @mouseover.prevent="changeIndex(2)" @mouseout="changeIndexBack(2)">
          </div>
        </div>

      </div>
      <!-- 自营产品 -->
      <div class="produce">
        <img src="../../static/sjImage/shouye/xinwenzixun.svg" alt="">
      </div>
      <!-- 轮播图2 -->
      <div class="wraps">
        <ul class="containers">
          <li>
            <div class="topImg">
              <div class="container">
                <div class="containerBox">
                  <div class="swiper-container">
                    <div class="swiper-wrapper wrap">
                      <div class="swiper-slide item" v-for="(item, index) in newslist" :key="index">
                        <img :src="item.img_url" alt="">
                        <div class="text">
                          <div class="timer">
                            <img src="../../static/sjImage/shouye/时间.svg" alt="">
                            <p>{{ item.create_time }}</p>
                          </div>
                          <div
                            style="width: 100%;margin: 0 auto;font-size: 24px;font-weight: 500;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                            {{ item.title }}</div>
                          <p class="txts">
                            {{ item.brief }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </li>
        </ul>
        <div class="btns">
          <!-- 上一张 -->
          <div class="swiper-button-prev leftBtn"></div>
          <!-- 下一张 -->
          <div class="swiper-button-next rightBtn"></div>
        </div>
      </div>
      <!-- 覆盖行业 -->
      <div class="produce">
        <img src="../../static/sjImage/shouye/覆盖行业.svg" alt="">
      </div>

      <ul class="types">
        <li>
          <img class="zhishi" src="../../static/sjImage/shouye/组合 544.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 545.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 546.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 547.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 549.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 548.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 550.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 551.svg" alt="">
        </li>
      </ul>
      <!-- 立即咨询 -->
      <div class="consultt" @click="showlxwm()">
        <img src="../../static/sjImage/shouye/组合 553.svg" alt="">
      </div>
      <!-- 客户信任 -->
      <div class="produce">
        <img src="../../static/sjImage/shouye/客户信任.svg" alt="">
      </div>
      <!--  列表-->
      <ul class="trust">
        <li>
          <img src="../../static/sjImage/shouye/组合 555.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 556.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 502.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/组合 558.svg" alt="">
        </li>
      </ul>
      <!-- 公司   -->
      <ul class="company">
        <li>
          <img src="../../static/sjImage/shouye/山东伟拓建筑劳务有限公司.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/河北玺逸建筑分包有限公司.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/天津呈瑞建筑安装有限公司.svg" alt="">
        </li>
        <li>
          <img src="../../static/sjImage/shouye/山东冀达兴建筑工程有限公司.svg" alt="">
        </li>
      </ul>
      <!-- 真诚专业 -->
      <div class="major">
        <div class="img">
          <img src="../../static/sjImage/shouye/组合 559.svg" alt="">
        </div>
        <p>
          <img
            src="../../static/sjImage/shouye/山东实钧科技服务有限公司始终坚持“诚德立信，品质为先”的经营理念，以“诚实守信，合作共赢，用户满意”的宗旨对待生产，对待销售。始终致力于打造“建筑行业+互联网行业的平台”，旨在为广大客户提供更高效、更优质的服务 to provide our customers with more efficient and better service 。.svg"
            alt="">
        </p>
      </div>
      <bottomView />
    </div>
  </div>
</template>
<script>
import headerView from '../../components/sj_components/header.vue'
import swiperView from '../../components/sj_components/swiper.vue'
import bottomView from '../../components/sj_components/bottom.vue'
import { Dialog } from 'vant';

export default {
  components: {
    headerView,
    bottomView,
    swiperView,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      showimg1: false,
      showimg2: false,
      pointImgList: [
        {
          thumb1: require('../../static/sjImage/shouye/image.png.svg')
        },
        {
          thumb1: require('../../static/sjImage/shouye/image.png.svg'),
        },
        {
          thumb1: require('../../static/sjImage/shouye/image.png.svg'),
        },
        {
          thumb1: require('../../static/sjImage/shouye/image.png.svg'),
        },
      ],
      pointImgList1: [
        {
          thumb1: require('../../static/sjImage/shouye/图层 1 拷贝.svg')
        },
        {
          thumb1: require('../../static/sjImage/shouye/图层 1 拷贝.svg'),
        },
        {
          thumb1: require('../../static/sjImage/shouye/图层 1 拷贝.svg'),
        },
        {
          thumb1: require('../../static/sjImage/shouye/图层 1 拷贝.svg'),
        },
      ],
      newslist: []
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    changeIndex(e) {
      console.log('手指触摸');
      console.log(111111);
      this[`showimg${e}`] = true;
    },
    changeIndexBack(e) {
      console.log('手指离开');
      console.log(222222);
      this[`showimg${e}`] = false;
    },
    bannerPoint() {
      new Swiper(".swiper-container", {
        loop: true,
        autoplay: {
          delay: 2000, //1秒切换一次
          disableOnInteraction: false
        },
        updateOnImagesReady: true,
        slidesPerView: 1,  //设置slider容器能够同时显示的slides数量

        observer: true,//在 Swiper 的上启用动态检查器(Mutation Observer)，如果你更改swiper 的样式（隐藏/显示）或修改其子元素（添加/删除幻灯片），Swiper 会更新（重新初始化）并触发 observerUpdate 事件。
        observeParents: true,
        observeSlideChildren: true,

        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next leftBtn', // 右按钮
          prevEl: '.swiper-button-prev rightBtn', // 左按钮
        },
      })
    },
    bannerPoint1() {
      new Swiper(".swiper-container contain", {
        loop: true,
        autoplay: {
          delay: 2000, //1秒切换一次
          disableOnInteraction: false
        },
        updateOnImagesReady: true,
        slidesPerView: 1,  //设置slider容器能够同时显示的slides数量

        observer: true,//在 Swiper 的上启用动态检查器(Mutation Observer)，如果你更改swiper 的样式（隐藏/显示）或修改其子元素（添加/删除幻灯片），Swiper 会更新（重新初始化）并触发 observerUpdate 事件。
        observeParents: true,
        observeSlideChildren: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next lefts', // 右按钮
          prevEl: '.swiper-button-prev rights', // 左按钮
        },
      })
    },
    getlist() {
      this.$http.get("/api/News/newsList?page=1&size=5").then((res) => {
        if (res.data.code == 1) {
          console.log(res.data.data.list);
          this.newslist = res.data.data.list
        }
      })
    },
    showlxwm() {
      Dialog.alert({
        message: '服务热线：400-687-1177',
        confirmButtonColor: "#3096DB"
      }).then(() => {
        // on close
      });
    }
  },
  mounted() {
    this.bannerPoint()
    this.bannerPoint1()
  }
}
</script>

<style scoped lang="less">
li {
  list-style: none;
}

body,
html {
  background-color: rgb(242, 242, 242);
}

.top {
  height: 1.4933rem;
  width: 100%;
  position: relative;
  margin-bottom: .2933rem;

  .logo {
    width: 2.7733rem;
    height: 1.12rem;
    position: absolute;
    left: .32rem;
    top: .32rem;
  }

  .sou {
    width: .64rem;
    height: .64rem;
    position: absolute;
    top: .64rem;
    left: 8.0533rem;
  }

  .more {
    width: .7467rem;
    position: absolute;
    height: 0.533rem;
    top: .6133rem;
    left: 8.9333rem;

  }

}

.bgi {
  width: 100%;
  height: 4rem;
  position: relative;

}

.four {
  background-color: rgb(242, 242, 242);

  width: 100%;
  padding: 0 .4267rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.32rem;

  img {
    width: 4.48rem;
    border-radius: 0.0533rem;
    overflow: hidden;

    height: 1.5467rem;
  }

  .img {
    width: 4.48rem;
    height: 1.5467rem;
    margin-top: 0.32rem;
    position: relative;
    border-radius: 0.0533rem;
    overflow: hidden;

    .imglogo {
      width: .64rem;
      position: absolute;
      top: .4533rem;
      left: 0.2133rem;
      height: 0.64rem;
      vertical-align: middle;
      align-items: center;
    }

    .imglogo .imgbg {
      width: 100%;
      position: absolute;
      left: 0;
      top: .32rem;
    }
  }

  img:nth-of-type(n+3) {
    margin-top: 0.32rem;
  }
}

/* 我们的服务 */
.serve {
  background-color: rgb(242, 242, 242);
  margin-top: 0.4267rem;
  margin-left: 0.4267rem;
  font-size: 0.4267rem;
  letter-spacing: 0;
  line-height: 0.64rem;
  font-weight: 400;
  color: rgb(20, 20, 20);
}

.fourr {
  width: 100%;
  padding: 0 .4267rem;
  margin-top: .4267rem;

  background-color: rgb(242, 242, 242);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    border-radius: 0.2133rem;
    margin-top: .32rem;
    position: relative;
    width: 4.32rem;
    height: 5.3867rem;
    border-radius: .2133rem;

    img {
      width: 100%;
    }

    .shebei,
    .wx {
      position: absolute;
      width: .8533rem;
      height: 0.8533rem;
      left: 1.7333rem;
      top: 0.2133rem;
    }
  }
}

/* 立即咨询 */


/* 移动端后台管理系统 */
.mangementSystem {
  font-weight: 400;
  // width: 3.36rem;
  height: .56rem;
  font-size: 0.3733rem;
  line-height: .56rem;
  color: rgb(20, 20, 20);
  margin-left: .4267rem;
}

.txt {
  margin-left: .4267rem;
  margin-top: .1067rem;
  padding-right: .4267rem;
  font-weight: 400;
  font-size: 0.2667rem;
  line-height: .4rem;
  color: #999;
}

/* 立即咨询 */
.consultt {
  margin: 0 auto;
  margin-top: .32rem;
  width: 4rem;
  height: 1.0667rem;

  img {
    width: 100%;
  }
}

/* 轮播图 */
.wrap {

  width: 5.9733rem;
  height: 6.8267rem;
  margin: 0 auto;
  margin-top: 1.44rem;
  margin-bottom: .5333rem;

  .btn {
    position: relative;

    .lefts {
      position: absolute;
      width: .5333rem;
      height: .5333rem;
      background-image: url(@/static/sjImage/shouye/zuo.svg);
      background-size: contain;
    }

    .rights {
      position: absolute;
      width: .5333rem;
      height: .5333rem;
      background-size: contain;
      background-image: url(@/static/sjImage/shouye/组合\ 607.svg);
    }
  }

  .containerr {
    width: 5.9733rem;
    height: 5.92rem;
    margin: 0 auto;

    li {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
      }

      // 轮播图样式
      .container {
        width: 100%;
        height: 100%;

        .containerBox {
          width: 100%;
          height: 100%;

          .contain {
            width: 100%;
            height: 100%;
          }

          .wrap {
            width: 100%;
            height: 100%;
            margin-top: 0;
          }

          .item {
            width: 100%;
            height: 100%;
          }

          .img {
            width: 100%;
            height: 100%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }

    }
  }

  .btn {
    width: 2.6667rem;
    height: .5333rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .32rem;

    .leftBtn {
      width: .5333rem;
      height: .5333rem;
    }

    .rightBtn {
      width: .5333rem;
      height: .5333rem;
    }

    img {
      width: 100%;
    }
  }
}

/* 自营产品 */
.produce {
  margin-left: .4267rem;
  margin-top: .5333rem;
  width: 1.7067rem;
  line-height: .64rem;
  height: .64rem;
  margin-bottom: .32rem;

  img {
    width: 100%;
  }
}

/* 轮播图2 */
.wraps {
  padding-left: 0.4267rem;
  margin-top: 0.4267rem;
  height: 8.4rem;

  .containers {
    li {
      height: 7.4667rem;
      position: relative;

      .topImg {
        width: 9.1467rem;
        height: 7.4667rem;

        .swiper-container {
          width: 100%;
          height: 100%;
        }

        //轮播图样式
        .container {
          width: 100%;
          height: 100%;

          .containerBox {
            width: 100%;
            height: 100%;

            .contain {
              width: 100%;
              height: 100%;
            }

            .wrap {
              width: 100%;
              height: 100%;
              margin-top: 0;
            }

            .item {
              width: 100%;
              height: 100%;
            }

            .img {
              width: 100%;
              height: 100%;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }

        img {
          width: 9.1467rem;
          height: 5.5467rem;
        }
      }

      .text {
        width: 8.7733rem;
        height: 2.7467rem;
        position: absolute;
        left: 0.1768rem;
        top: 4.7069rem;
        border-radius: 0.0533rem;
        padding: .1rem .4rem;
        background-color: #fff;

        .timer {
          height: .64rem;

          img {
            width: .5333rem;
            height: .5333rem;
            vertical-align: middle;
            margin-right: .16rem;
          }

          p {
            font-weight: 400;
            font-size: .4267rem;
            line-height: .64rem;
            display: inline-block;
            vertical-align: middle;
            color: rgb(128, 128, 128);

          }

          margin-bottom: .1067rem;
        }

        h3 {
          font-weight: 400;
          font-size: 0.533rem;
          line-height: .8rem;
          color: rgb(20, 20, 20);
          margin-bottom: .16rem;
        }

        .txts {
          font-weight: 400;
          font-size: 0.4267rem;
          line-height: .64rem;
          color: rgb(128, 128, 128);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .btns {
    width: 1.6rem;
    height: .64rem;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .32rem;
    position: relative;
    color: rgba(255, 255, 255, 0.01) !important;

    .swiper-button-prev {
      color: rgba(255, 255, 255, 0.01) !important;
    }

    .swiper-button-next {
      color: rgba(255, 255, 255, 0.01) !important;
    }
  }

  .leftBtn {
    width: .64rem;
    height: .64rem;
    position: absolute;
    background-image: url(@/static/sjImage/shouye/zuo2.svg);
    background-size: contain;

  }

  .rightBtn {
    position: absolute;
    width: .64rem;
    height: .64rem;
    background-image: url(@/static/sjImage/shouye/you.svg);
    background-size: contain;
  }


}

/* 覆盖行业 */
.types {
  width: 100%;
  padding: 0 .4267rem;
  margin-top: .32rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  li {
    width: 4.32rem;
    height: 1.52rem;

    img {
      width: 100%;
    }
  }

  li:nth-of-type(n+3) {
    margin-top: .3467rem;
  }
}

/* 客户信任 */
.trust {
  width: 100%;
  padding: 0 1.76rem;
  display: flex;
  margin-top: .32rem;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: .96rem;

  li {
    height: 1.1733rem;

    img {
      height: 100%;
    }
  }

  li:nth-of-type(n+3) {
    margin-top: .32rem;
  }
}

/* 公司 */
.company {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 .4267rem;
  margin-bottom: .2133rem;

  li {
    width: 4.32rem;
    height: 1.0667rem;
    background-color: #fff;
    line-height: 1.0667rem;
    text-align: center;
    position: relative;

    img {
      line-height: .4267rem;
      width: 3.84rem;
      height: .4267rem;
      position: absolute;
      top: .32rem;
      left: .24rem;
    }

    img:nth-of-type(4) {
      width: 4.16rem;
      height: .4267rem;
    }
  }

  li:nth-of-type(n+3) {
    margin-top: .32rem;
  }
}

/* 真诚专业 */
.major {
  width: 100%;
  height: 5.2267rem;
  background-repeat: no-repeat;
  background-image: url(../../static/sjImage/shouye/矩形\ 215.svg);
  background-size: cover;
  padding-left: .4267rem;
  padding-top: .32rem;

  .img {
    width: 1.92rem;
    height: .8533rem;

    img {
      width: 100%;
    }
  }

  p {
    width: 7.1467rem;
    height: 2.4rem;
    margin-top: .48rem;

    img {
      width: 100%;
    }
  }
}
</style>