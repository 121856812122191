<template>
  <!-- <div> -->
  <div class="container">
    <div class="containerBox">
      <div class="swiper-container contain">
        <div class="swiper-wrapper wrap">
          <div class="swiper-slide item" v-for="(item, index) in pointImgList" :key="index">
            <img class="img" :src="item.thumb1" alt="">
            <img class="sun" src="../../static/sjImage/shouye/20210919-003840-9f93.svg" alt="">
            <img class="apply" src="../../static/sjImage/shouye/提供 一站式行业解决方案.svg" alt="">
            <p class="chuang">创新与技术服务解决方案</p>
            <img src="../../static/sjImage/shouye/swiperimgleft.png" class="bgRight" alt="">
          </div>
        </div>
        <!-- 如果需要导航按钮 -->
        <!-- 上一张 -->
        <div class="swiper-button-prev leftBtn"></div>
        <!-- 下一张 -->
        <div class="swiper-button-next rightBtn"></div>
        <!-- 分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import Swiper from "swiper"
export default {
  name: 'swiper',
  data() {
    return {
      pointImgList: [
        {
          thumb1:require('../../static/sjImage/shouye/bg1.png')
        },
        {
          thumb1:require('../../static/sjImage/shouye/bg1.png'),
        },
        {
          thumb1:require('../../static/sjImage/shouye/bg1.png'),
        },
        {
          thumb1:require('../../static/sjImage/shouye/bg1.png'),
        },
      
      ]
    }
  },
  methods: {
    bannerPoint() {
      new Swiper(".swiper-container", {
        loop: true,
        autoplay: {
          delay: 2000, //1秒切换一次
          disableOnInteraction: false
        },
        updateOnImagesReady: true,
        slidesPerView: 1,  //设置slider容器能够同时显示的slides数量

        observer: true,//在 Swiper 的上启用动态检查器(Mutation Observer)，如果你更改swiper 的样式（隐藏/显示）或修改其子元素（添加/删除幻灯片），Swiper 会更新（重新初始化）并触发 observerUpdate 事件。
        observeParents: true,
        observeSlideChildren: true,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          paginationClickable: true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next', // 右按钮
          prevEl: '.swiper-button-prev', // 左按钮
        },
      })
    }
  },
  mounted() {
    this.bannerPoint()
  },
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;

  .containerBox {
    width: 100%;
    height: 100%;

    .contain {
      width: 100%;
      height: 100%;
    }

    .wrap {
      width: 100%;
      height: 100%;
    }

    .item {
      width: 100%;

    }

    .bg {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    p {
      position: absolute;
      left: .8rem;
      bottom: -0.5rem;
      color: white;
      font-size: .32rem;
      line-height: 2.6133rem;
      font-weight: 400;
      // z-index:2;
      // width: 390px;
      // height: 90px;
    }

    .bgRight {
      position: absolute;
      width: 4.56rem;
      height: 3.76rem;
      top: 0.1006rem;
      right: 0.426rem;
    }

    .apply {
      width: 720px;
      height: 196px;
      position: absolute;
      left: 0.9rem;
      top: 1.2rem;
      bottom: 243px;

      z-index: 1;
    }

    .sun {
      width: 1.5733rem;
      height: 1.28rem;
      position: absolute;
      top: .5333rem;
      left: .64rem;
    }

    .img {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>