<template>
  <div class="allBottom">
    <div class="content">
      <div class="first">
        <img src="../../../images/shouye/logo.png" alt="">
        <p>山东实钧科技服务有限公司</p>
      </div>
      <ul class="second">
        <li>产品服务</li>
        <li>网站建设</li>
        <li>app开发</li>
        <li>小程序开发</li>
        <li>公众号开发</li>
      </ul>
      <ul class="second">
        <li>关于我们</li>
        <li>网站首页</li>
        <li>公司简介</li>
        <li>自营产品</li>
        <li>发展历程</li>
      </ul>
      <ul class="second">
        <li>联系我们</li>
        <li>联系电话：400-687-1177</li>
        <li>邮箱：shijunzhigong@163.com</li>
        <li>地址:济南市高新区工业南路65-3号</li>
      </ul>
      <div class="ewm">
        <img src="../../../images/shouye/bc88d1d1-bcb9-46e7-8a0b-7cdee5ef6b4f1992482 1@1x.png" alt="">
      </div>
    </div>
    <div class="beian" @click="tobeian">
      备案号：鲁ICP备2021008564号-3
    </div>
  </div>
</template>

<script>
export default {
  name: 'bottomView',
  methods:{
    tobeian(){
      console.log(1231)
      window.open("https://beian.miit.gov.cn/")
    }
  }
}


</script>

<style scoped lang="less">
html, body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

.allBottom {
  width: 100%;
  height: 295px;
  position: relative;

  .content {
    width: 1400px;
    height: 250px;
    position: absolute;
    left: 260px;
    top: 44px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .first {
      width: 312px;

      img {
        width: 245px;
        height: 104px;
        margin-bottom: 20px;
      }

      p {
        width: 312px;
        // height: 38px;
        font-weight: 700;
        font-size: 26px;
        line-height: 38px;
        letter-spacing: 0;

      }
    }

    .second {

      // width: 80px;
      height: 209px;

      li:nth-child(1) {
        font-size: 20px;
        line-height: 30px;
        color: rgb(20, 20, 20);
      }

      li:nth-child(n+2) {
        font-size: 16px;
        color: rgb(128, 128, 128);
        line-height: 24px;
        margin: 21px 0;
      }
    }

    .ewm {
      width: 194px;
      height: 194px;

      img {
        width: 100%;
      }
    }
  }
}

.beian {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  color: rgb(128, 128, 128);
  cursor: pointer;
}
</style>