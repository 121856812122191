import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import 'lib-flexible'
Vue.config.productionTip = false
import BaiduMap from 'vue-baidu-map'
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'


import ElementUI from 'element-ui'; // 引入element-ui
import 'element-ui/lib/theme-chalk/index.css'; // element-ui的css样式要单独引入

import axios from 'axios'
axios.defaults.baseURL = 'https://manager-api.sdshijun.cn/' //请求默认域名
Vue.prototype.$http = axios
// import { DropdownMenu, DropdownItem } from 'vant';

// Vue.use(DropdownMenu);
// Vue.use(DropdownItem);
//引入vant
// import { Button } from 'vant' // 引入Button组件
// Vue.use(Button) // 全局注册
Vue.use(ElementUI); // 这种方式引入了ElementUI中所有的组件
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'tUM4R5cQmBjYgvAGfVei3XrwA9GG3u2y'
})
//引入swiper

let screenWidth = window.innerWidth;
new Vue({
  router,
  store,
  screenWidth,
  render: h => h(App)
}).$mount('#app')