<template>
  <div id="app">
    <div v-if="isMobile()">
      <router-view></router-view>
    </div>
    <div v-else>
      <div class="topList">
        <img src="./images/shouye/logo.png" alt="" class="logo">
        <ul class="nav" @click="showLine($event)" id="navs">

          <router-link to="/pc_home" tag="li">首页</router-link>

          <router-link to="/company" tag="li">公司简介</router-link>

          <router-link to="/serve" tag="li">企业服务</router-link>

          <router-link to="/news" tag="li">新闻资讯</router-link>

          <router-link to="/shijun" tag="li">
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                实钧智工
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="产品介绍">产品介绍</el-dropdown-item>
                <el-dropdown-item command="产品定价">产品定价</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </router-link>
          <router-link to="/pracing" tag="li" v-show="showNav">产品定价</router-link>
        </ul>
      </div>
      <router-view></router-view>
    </div>

  </div>
</template>
<script>
import HomePC from '@/views/view-PC/HomeView.vue'
import navView from '@/views/view-iphone/navIndex.vue'
import homesj from '@/views/view-iphone/index.vue'
import router from './router'
export default ({
  components: {
    HomePC,
    homesj,
    navView
  },
  data() {
    return {
      navArr: ['首页', '公司简介', '解决方案', '新闻资讯', '实钧智工', '产品介绍'],
      showNav: false
    }
  },
  //在 App.vue 里添加
  methods: {
    // 添加判断方法
    isMobile: function () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    showLine: function (e) {
      for (let i = 0; i < this.navArr.length; i++) {
        navs.children[i].style.borderBottom = 'none'
      }
      if (e.target.tagName == "LI") {
        e.target.style.borderBottom = '3px solid rgb(48, 150, 219)'
      }
      if (e.target.innerText == '实钧智工') {
        e.target.parentNode.parentNode.parentNode.parentNode.style.backgroundColor = 'rgb(237, 249, 255)'
      } else {
        e.target.parentNode.parentNode.style.backgroundColor = 'rgb(239, 239, 239)'
      }
    },
    handleCommand(command) {
      console.log(command);
      if (command == '产品介绍') {
        this.$router.push('/intro')

        for (let i = 0; i < this.navArr.length; i++) {
          navs.children[i].style.borderBottom = 'none'
        }
        navs.parentNode.style.backgroundColor = 'rgb(237, 249, 255)'
        navs.children[navs.children.length - 2].style.borderBottom = '3px solid rgb(48, 150, 219)'
      }
      if (command == '产品定价') {
        this.$router.push('/pracing')
        this.showNav = true
        for (let i = 0; i < this.navArr.length; i++) {
          navs.children[i].style.borderBottom = 'none'
          navs.parentNode.style.backgroundColor = 'rgb(239, 239, 239)'
        }
        navs.children[navs.children.length - 1].style.borderBottom = '3px solid rgb(48, 150, 219)'
      }
    },

  },
  mounted() {
    // this.$router.push({
    //   path: "/pc_home"
    // });
    if (this.isMobile()) {
      console.log('手机端')
      this.$router.replace('/sj_home');
    } else {
      console.log('pc端');

      this.$router.replace({
        path:"/pc_home"
      });
    }
    navs.children[0].style.borderBottom = '3px solid rgb(48, 150, 219)'
  },
})
</script>

<style  lang="less">
body {
  background-color: rgb(242, 242, 242);
  box-sizing: border-box;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li {
  list-style: none;
}

.active {
  border-bottom: 1px solid rgb(20, 20, 20);
}

/* 样式 */
.top {
  height: 1.4933rem;
  width: 100%;
  position: relative;

  .logo {
    width: 2.7733rem;
    height: 1.12rem;
    position: absolute;
    left: .32rem;
    top: .32rem;
  }

  .sou {
    width: .64rem;
    height: .64rem;
    position: absolute;
    top: .64rem;
    left: 8.0533rem;
  }

  .more {
    width: .7467rem;
    position: absolute;
    height: 0.533rem;
    top: .6133rem;
    left: 8.9333rem;

  }

}

.topList {

  width: 100%;
  height: 80px;
  background: rgb(239, 239, 239);
  position: relative;

  .logo {
    position: absolute;
    width: 104px;
    height: 42px;
    left: 260px;
    top: 19px;
  }

  .nav {
    position: absolute;
    top: 26px;
    left: 695px;
    font-size: .48rem;
    font-weight: 400;
    width: 732px;
    height: 37px;
    display: flex;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    justify-content: space-evenly;
    color: rgb(20, 20, 20);

    li {
      cursor: pointer;
    }

    .el-dropdown-link {
      font-size: 18px;
      color: rgb(20, 20, 20);
    }

    /deep/.el-dropdown-item {
      font-size: .48rem;
      font-weight: 400;
    }
  }
}
</style>
