<template>
    <div>
        <div class="allNav">
            <ul>
                <li @click="gotoHome"> <!-- 首页 -->
                    <img src="../../static/sjImage/navImage/组合 514.svg" alt="">
                </li>
                <li @click="gotoCompany"><!-- 公司简介 -->
                    <img src="../../static/sjImage/navImage/组合 519.svg" alt="">
                </li>
                <li @click="gotoServe"><!-- 企业服务 -->
                    <img src="../../static/sjImage/navImage/组合 515.svg" alt="">
                </li>
                <li @click="gotoNews"><!-- 新闻资讯 -->
                    <img src="../../static/sjImage/navImage/组合 516.svg" alt="">
                </li>
                <li @click="gotoshijun"><!-- 实钧智工 -->
                    <img src="../../static/sjImage/navImage/组合 517.svg" alt="">
                </li>
                <li @click="gotoPracing"><!-- 实钧智工定价 -->
                    <img src="../../static/sjImage/navImage/组合 1721.svg" alt="">
                </li>
            </ul>
            <div class="btn" @click="sendMsg">
                <img src="../../static/sjImage/navImage/关闭-小.svg" alt="">
            </div>
        </div>
       
    </div>
</template>

<script>
export default {
    name: 'navIndexView',
    data() { 
        return {

        }
    },
    methods: {
        sendMsg() {
            this.$router.push(
                {
                  path:'/sj_home'
                }
            )
        },
        gotoHome(){
            this.$router.push('/sj_home')
        },
        gotoCompany() {
            this.$router.push('/sj_company')
        },
        gotoServe(){
            this.$router.push('/sj_serve')
        },
        gotoshijun(){
            this.$router.push('/sj_shijun')
        },
        gotoNews(){
            this.$router.push('/sj_news')
        },
        gotoPracing(){
            this.$router.push('/shijunpracing')
        }
    },
}
</script>

<style scoped lang="less">
.allNav {
    width: 100%;
    height: 21.6533rem;
    padding: 0 16px;
    background-color: rgb(48, 150, 219);
    position: relative;

    ul {
        position: absolute;
        top: 1.76rem;
        width: 8.1467rem;
        height: 11.2rem;
        left: .4267rem;

        li {
            height: 1.6rem;
            width: 100%;
            margin-top: .32rem;
            border-radius: 2.1333rem;
            background-color: rgb(255, 255, 255);

            img {
                width: 100%;
            }
        }

        li:nth-of-type(1) {
            margin-top: 0;
        }
    }

    .btn {
        width: .64rem;
        height: .64rem;
        position: absolute;
        top: .4267rem;
        left: 8.9333rem;

        img {
            width: 100%;

        }
    }
}
</style>