<template>
  <div>
    <div class="top">

<img class="logo" src="../../static/sjImage/shouye/微信图片_20220712100528 6.svg" alt="">
<img class="sou" src="../../static/sjImage/shouye/搜索.svg" alt="">
  <img class="more" src="../../static/sjImage/shouye/组合 512.svg" @click="func"  alt="">

    </div>
  </div>
</template>

<script>
export default {
    name:'headerView',
methods:{
    func(){
     
     this.$router.push({
       name:'navIndexView'
     })
   }
}
}
</script>

<style scoped lang="less">
.top {
  height: 1.7333rem;
  width: 100%;
  position: relative;
  padding-bottom: .2933rem;

  .logo {
    width: 2.7733rem;
    height: 1.12rem;
    position: absolute;
    left: .32rem;
    top: .32rem;
  }

  .sou {
    width: .64rem;
    height: .64rem;
    position: absolute;
    top: .64rem;
    left: 8.0533rem;
  }

  .more {
    width: .7467rem;
    position: absolute;
    height: 0.533rem;
    top: .6133rem;
    left: 8.9333rem;

  }

}
</style>