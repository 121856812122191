<template>
  <!-- <div> -->
    <div class="bottom">
      <img class="logo" src="../../static/sjImage/company/微信图片_20220712100528 7.svg" alt="">
      <img class="tel"  src="../../static/sjImage/company/联系电话：400-687-1177.svg" alt="">
      <img  class="youxiang" src="../../static/sjImage/company/邮箱：shijunzhigong@163.com.svg" alt="">
      <img class="add" src="../../static/sjImage/company/地址_济南市高新区工业南路65-3号.svg" alt="">
      <img class="ewm" src="../../static/sjImage/shouye/bc88d1d1-bcb9-46e7-8a0b-7cdee5ef6b4f1992482 3.svg" alt="">
     </div>
  <!-- </div> -->
</template>

<script>
export default {
name:'bottomView'
}
</script>

<style scoped lang="less">
.bottom{
  width: 100%;
  height: 8.08rem;
padding-bottom: .4267rem;

 img{
  display: block;
  margin: 0 auto;
 }
  .logo{
    padding-top: 1.0667rem;
 
  }
  .tel{
    margin-top: .4267rem;
    width: 4.1867rem;
    height: .56rem;

  }
  .youxiang{
    width: 5.3867rem;
    height: .56rem;
    margin-top: .32rem;
  }
  .add{
    width: 5.7067rem;
    height: .56rem;
    margin-top: .32rem;
  }
  .ewm{
    width: 2.1333rem;
    height: 2.1333rem;
    margin-top: .4267rem;

  }
}
</style>