<template>
  <div>
    <div class="container" circular=“true”>
      <div class="containerBox" circular=“true”>
        <div class="swiper-container" circular=“true”>
          <div class="swiper-wrapper" circular=“true”>
            <div class="swiper-slide item" circular=“true” v-for="(item, index) in pointImgList"
              style="background-image: url(item.thumb1);" :key="index">
              <div class="left">
                <h5>移动端后台管理系统</h5>
                <p style="">
                  专为企业研发的一款信息化管理软件，采用最新的云端技术，将企业员工都纳入到标准化的管理流程中，实现信息数据互通互联。该系统通过手机端、微信端无缝对接，形成管理上的闭环，从而简化管理流程，缩短响应时间、降低运营成本、提升整体工作效率及品质。系统主要适用于工程、家装、软装、园林等建筑工程相关领域。
                </p>
                <button class="more"  @click="dialogVisible = true">了解更多</button>
              </div>
              <div class="rightImage">
                <img src="@/images/shouye/shijunzhigongone.png" alt="">
              </div>
            </div>
          </div>
          <!-- 分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>服务热线：400-687-1177</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import Swiper from "swiper"
export default {
  name: 'swipersTwo',
  data() {
    return {
      pointImgList: [
        {
          thumb1: require('../../images/shouye/juxing1.png')
        },
        {
          thumb1: require('../../images/shouye/juxing1.png'),
        },
        {
          thumb1: require('../../images/shouye/juxing1.png'),
        },
        {
          thumb1: require('../../images/shouye/juxing1.png'),
        },
      ],
      dialogVisible:false
    }
  },
  methods: {
    bannerPoint() {
      new Swiper(".swiper-container", {
        loop: true,
        autoplay: {
          delay: 2000, //2秒切换一次
          disableOnInteraction: false
        },
        updateOnImagesReady: true,
        slidesPerView: 1,  //设置slider容器能够同时显示的slides数量

        observer: true,//在 Swiper 的上启用动态检查器(Mutation Observer)，如果你更改swiper 的样式（隐藏/显示）或修改其子元素（添加/删除幻灯片），Swiper 会更新（重新初始化）并触发 observerUpdate 事件。
        observeParents: true,
        observeSlideChildren: true,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          paginationClickable: true,
        },
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next', // 右按钮
          prevEl: '.swiper-button-prev', // 左按钮
          // disabledClass: 'my-button-disabled',
        },
        on: {
          click: function () {
            // alert(this.clickedIndex); //当前点击的是第几张
          },
          transitionEnd: function (event) {
            // img_index = this.activeIndex; //滑动到第几张
          },
        }

      })
    }
  },
  mounted() {
    this.bannerPoint()
  },
}
</script>
  
<style scoped lang="less">
.container {
  width: 1118px;
  height: 612px;

}

.containerBox {
  width: 100%;
  height: 100%;

  .item {
    width: 100%;
    height: 612px;
    width: 1118px;
    // height: 612px;
    position: right;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .left {
      width: 27vw;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;

      h5 {
        font-size: 26px;
        line-height: 38px;
        position: absolute;
        left: 0.28rem;
        top: 0.72rem;
      }

      p {
        width: 436px;
        height: 216px;
        width: 436px;
        top: 178px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        position: absolute;
        left: 0.28rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
        top: 1.0rem;

      }

      .more {
        width: 300px;
        height: 80px;
        border-radius: 4px;
        border: 3px solid rgb(48, 150, 219);
        background-color: rgb(247, 250, 253);
        font-weight: 500;
        font-size: 26px;
        line-height: 38px;
        color: rgb(48, 150, 219);
        position: absolute;
        left: 116px;
        bottom: 70px;
        background-color: none;
      }
    }

    .rightImage {
      width: 512px;
      height: 512px;
      position: absolute;
      top: 50px;
      right: 50px;

      img {
        width: 100%;
      }
    }
  }

  .img {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}</style>