<template>
    <div>
        <!-- 轮播组件 -->
        <div class="swiper">
            <swiperView />
            <ul class="four" style="">
                <li><img src="../../images/shouye/yuanma.png" alt=""></li>
                <li><img src="../../images/shouye/shejishi.png" alt=""></li>
                <li><img src="../../images/shouye/quanchengbaozhang.png" alt=""></li>
                <li><img src="../../images/shouye/duoyuyan.png" alt=""></li>
            </ul>
        </div>
        <div class="all2">
            <div class="title">
                <h5>我们能做什么？</h5>
                <p>我们能做什么?我们能做什么?我们能做什么?我们能做什么?</p>
            </div>
            <div class="block">
                <div class="blockItem" @mouseover="changeIndex(1)" @mouseout="changeIndexBack(1)">
                    <div class="img">
                        <img src="../../static/pc_shouye/电脑 2.png" v-show="!showimg1" alt="">
                        <img src="../../static/pc_shouye/diannao4@1x.png" v-show="showimg1" alt="">
                    </div>
                    <div class="txt">
                        <h5>网站定制</h5>
                        <p>h5定制开发</p>
                        <p>高端网站开发</p>
                        <p>管理系统定制开发</p>
                        <p>电商系统定制开发</p>
                        <p>更多定制服务咨询客服</p>
                    </div>
                </div>
                <div class="blockItem" @mouseover="changeIndex(2)" @mouseout="changeIndexBack(2)">
                    <div class="img">
                        <img src="../../static/pc_shouye/微信小程序@1x.png" alt="" v-show="!showimg2">
                        <img src="../../static/pc_shouye/wxxcx.png" alt="" v-show="showimg2">

                    </div>
                    <div class="txt">
                        <h5>小程序定制</h5>
                        <p>电商零售</p>
                        <p>餐饮外卖</p>
                        <p>健康运动</p>
                        <p>考勤定位</p>
                        <p>更多定制服务咨询客服</p>
                    </div>
                </div>
                <div class="blockItem" @mouseover="changeIndex(3)" @mouseout="changeIndexBack(3)">
                    <div class="img">
                        <img src="../../static/pc_shouye/微信@1x.png" v-show="!showimg3" alt="">
                        <img src="../../static/pc_shouye/wx@1x.png" v-show="showimg3" alt="">
                    </div>
                    <div class="txt">
                        <h5>微信公众号定制</h5>
                        <p>微官网</p>
                        <p>微商城</p>
                        <p>微分销</p>
                        <p>微行业</p>
                        <p>更多定制服务咨询客服</p>
                    </div>
                </div>
                <div class="blockItem" @mouseover="changeIndex(4)" @mouseout="changeIndexBack(4)">
                    <div class="img">
                        <img src="../../static/pc_shouye/设备.png" v-show="!showimg4" alt="">
                        <img src="../../static/pc_shouye/shebei@1x.png" v-show="showimg4" alt="">
                    </div>
                    <div class="txt">
                        <h5>APP定制</h5>
                        <p>综合商城</p>
                        <p>金融贸易</p>
                        <p>知识付费/教育学习</p>
                        <p>生活服务</p>
                        <p>更多定制服务咨询客服</p>
                    </div>
                </div>
            </div>
            <button @click="dialogVisible = true">立即咨询</button>
        </div>
        <!-- 第三部分  轮播图  -->
        <div class="all3">
            <div class="title">
                <!-- <img src="../../images/shouye/zuhe473.svg" alt=""> -->
                <h5>自研产品</h5>
                <p>我们能做什么?我们能做什么?我们能做什么?我们能做什么?</p>
            </div>
            <div class="container">
                <!-- 左按钮 -->
                <button class="swiper-button-prev leftBtn" @mouseover="changeIndex(5)" @mouseout="changeIndexBack(5)">
                    <img src="../../images/shouye/leftnocheck.png" v-show="!showimg5" alt="">
                    <img src="../../static/pc_shouye/组合 608.png" v-show="showimg5" alt="">
                </button>

                <div class="showImg">
                    <swipersTwo />
                </div>
                <!-- 右按钮 -->
                <button class="swiper-button-next rightBtn" @mouseover="changeIndex(6)" @mouseout="changeIndexBack(6)">
                    <img src="../../static/pc_shouye/组合 607.png" v-show="!showimg6" alt="">
                    <img src="../../images/shouye/rgihtcheck.png" v-show="showimg6" alt="">
                </button>
            </div>
        </div>
        <!-- 第四部分 -->
        <div class="all4">
            <div class="title">
                <h5>新闻资讯</h5>
                <p>新闻资讯、新闻资讯、新闻资讯、新闻资讯、新闻资讯</p>
            </div>
            <div class="block">
                <div class="blockItem" @mouseover="changeIndex(7)" @mouseout="changeIndexBack(7)">
                    <div class="img" style="overflow: hidden;">
                        <img :src="newslist[0].img_url" alt="">

                    </div>
                    <div class="txt">
                        <p class="timer">
                            <img src="../../images/shouye/time1.png" v-show="!showimg7" alt="">
                            <img src="../../static/pc_shouye/时间@1x.png" v-show="showimg7" alt="">
                            <span>{{ newslist[0].create_time }}</span>
                        </p>
                        <div class="timer_title">
                            {{ newslist[0].title }}
                        </div>
                        <p class="content">{{ newslist[0].brief }}</p>
                    </div>
                </div>
                <div class="blockItem" @mouseover="changeIndex(8)" @mouseout="changeIndexBack(8)">
                    <div class="img" style="overflow: hidden;">
                        <img :src="newslist[1].img_url" alt="">
                    </div>
                    <div class="txt">
                        <p class="timer">
                            <img src="../../images/shouye/time1.png" v-show="!showimg8" alt="">
                            <img src="../../static/pc_shouye/时间@1x.png" v-show="showimg8" alt="">
                            <span>{{ newslist[1].create_time }}</span>
                        </p>
                        <div class="timer_title">
                            {{ newslist[1].title }}
                        </div>
                        <p class="content">{{ newslist[1].brief }}</p>
                    </div>
                </div>
                <div class="blockItem">
                    <main class="timerItem">
                        <h5>{{ newslist[0].title }}</h5>
                        <p><img src="../../images/shouye/time1.png" alt=""> <span>{{ newslist[0].create_time }}</span></p>
                    </main>
                    <main class="timerItem">
                        <h5>{{ newslist[1].title }}</h5>
                        <p><img src="../../images/shouye/time1.png" alt=""> <span>{{ newslist[0].create_time }}</span></p>
                    </main>
                    <main class="timerItem">
                        <h5>{{ newslist[2].title }}</h5>
                        <p><img src="../../images/shouye/time1.png" alt=""> <span>{{ newslist[0].create_time }}</span></p>
                    </main>
                </div>
                <router-link to="news"><button class="more">了解更多</button></router-link>
            </div>

        </div>
        <!-- 第五部分 -->
        <div class="all5">
            <div class="box">
                <div class="title">
                    <h5>覆盖行业</h5>
                    <p>我们能什么?我们能什么?我们能什么?我们能什么?我们能什么?</p>
                </div>
                <ul class="content">
                    <li>
                        <img src="../../images/shouye/zhishifufei.png" style="width: 100%;" alt="">
                    </li>
                    <li>
                        <img src="../../images/shouye/fenqi.png" style="width: 100%;" alt="">
                    </li>
                    <li>
                        <img src="../../images/shouye/tuanduichou.png" style="width: 100%;" alt="">
                    </li>
                    <li>
                        <img src="../../images/shouye/dianshang.png" style="width: 100%;" alt="">
                    </li>
                    <li>
                        <img src="../../images/shouye/xinlingshou.png" style="width: 100%;" alt="">
                    </li>
                    <li>
                        <img src="../../images/shouye/luliu.png" style="width: 100%;" alt="">
                    </li>
                    <li>
                        <img src="../../images/shouye/yiliaomenkan.png" style="width: 100%;" alt="">
                    </li>
                    <li>
                        <img src="@/images/shouye/hulianwang.png" style="width:100%;" alt="">
                    </li>
                </ul>
                <button class="more" @click="dialogVisible = true">了解更多</button>
            </div>
        </div>
        <!-- 第六部分 -->
        <div class="all6">
            <div class="box">
                <div class="title">
                    <h5>客户信任</h5>
                    <p>我们能做什么?我们能做什么?我们能做什么?我们能做什么?</p>
                </div>
                <div class="content">
                    <ul class="type">
                        <li>
                            <img src="../../images/shouye/xueli.png" alt="">
                            <div>
                                <h4>2000+</h4>
                                <p>精准客户</p>
                            </div>
                        </li>
                        <li>
                            <img src="../../images/shouye/xueli.png" alt="">
                            <div>
                                <h4>4000+</h4>
                                <p>服务企业用户</p>
                            </div>
                        </li>
                        <li>
                            <img src="../../images/shouye/xueli.png" alt="">
                            <div>
                                <h4>40000+</h4>
                                <p>技术沉淀</p>
                            </div>
                        </li>
                        <li>
                            <img src="../../images/shouye/xueli.png" alt="">
                            <div>
                                <h4>100%</h4>
                                <p>用户口碑推荐</p>
                            </div>
                        </li>
                    </ul>
                    <ul class="company">
                        <li>山东伟拓建筑劳务有限公司</li>
                        <li>河北玺逸建筑分包有限公司</li>
                        <li>天津呈瑞建筑安装有限公司</li>
                        <li>山东冀达兴建筑工程有限公司</li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <clientView  /> -->
        <!-- 第七部分 -->
        <div class="all7">
            <div class="title">
                <!-- <p>真诚/专业</p> -->
                <img src="../../images/shouye/zuhe12.svg" alt="">
            </div>
            <div class="content">

                <img src="../../images/shouye/sdsjtxt.svg" alt="">

            </div>
            <div class="mengban">
                <img src="../../images/shouye/mengban.png" class="" style="position: absolute;top: 0;left: 0; width: 100%;"
                    alt="">
                <p style="position: absolute;width: 100%;height: 100%; top: 0;left: 0; background: rgba(48, 150, 219,.8);">
                </p>
            </div>
        </div>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <span>服务热线：400-687-1177</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 底部组件 -->
        <bottomView />
    </div>
</template>

<script>
import swiperView from '@/components/sj_components/swiper.vue'// 引入轮播组件
import bottomView from '@/components/HomeView/pageEight/bottom.vue'  //引入底部组件
import swipersTwo from '@/components/HomeView/swiper.vue'
export default {
    name: 'HomePC',
    components: {
        swiperView,
        bottomView,
        swipersTwo,
    },
    data() {
        return {
            showimg1: false, //显示与隐藏
            showimg2: false,
            showimg3: false,
            showimg4: false,
            showimg5: false,
            showimg6: false,
            showimg7: false,
            showimg8: false,
            newslist: [],
            dialogVisible: false
        }
    },
    created() {
        this.getlist()
    },
    methods: {
        /* 第二三部分 */
        changeIndex(e) {
            this[`showimg${e}`] = true;
        },
        // 移出
        changeIndexBack(e) {
            this[`showimg${e}`] = false;
        },
        getlist() {
            this.$http.get("/api/News/newsList?page=1&size=10").then((res) => {
                console.log(res);
                if (res.data.code == 0) {
                    console.log(res.data.data.list);
                    this.newslist = res.data.data.list
                }
            })
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        }
    }
}
</script>
<style scoped lang="less">
.swiper {
    width: 100%;
    height: 640px;
    position: relative;

    .four {
        width: 1400px;
        height: 120px;
        position: absolute;
        display: flex;
        justify-content: space-around;
        z-index: 100;
        bottom: -70px;
        left: 50%;
        margin-left: -720px;

        li {
            width: 340px;
            height: 120px;

            img {
                width: 100%;
            }
        }

        li:nth-of-type(n+2) {
            margin-left: 13px;
        }
    }
}

li {
    list-style: none;
}

/* 第二部分 */
.all2 {
    background-color: rgb(247, 250, 253);
    position: relative;
    height: 896px;
    width: 100%;

    .title {
        width: 560px;
        height: 84px;
        position: absolute;
        top: 137px;
        left: 680px;
        text-align: center;

        h5 {
            width: 196px;
            height: 41px;
            line-height: 41px;
            font-weight: 700;
            font-size: 28px;
            text-align: center;
            color: rgb(20, 20, 20);
            margin: 0 auto;
        }

        p {
            height: 30px;
            font-weight: 400;
            font-size: 20px;
            margin-top: 20px;
            line-height: 30px;
            color: rgb(128, 128, 128);
        }
    }

    .block {
        width: 1380px;
        height: 380px;
        position: absolute;
        margin: 0 auto;
        bottom: 170px;
        left: 50%;
        margin-left: -690px;
        display: flex;
        justify-content: space-around;

        // flex-direction:row-reverse;
        .blockItem {
            width: 340px;
            height: 380px;
            text-align: center;
            background-color: #fff;
            left: 260px;


            .img {
                width: 52px;
                height: 52px;
                margin: 10px auto 20px;

                img {
                    width: 52px;
                    height: 52px;
                    margin: 10px auto 20px;
                }
            }

            h5 {
                font-weight: 500;
                font-size: 26px;
                line-height: 38px;
                height: 38px;
                color: rgb(20, 20, 20);
                margin-bottom: 20px;
            }

            p {
                height: 30px;
                line-height: 30px;
                font-weight: 400;
                font-size: 20px;
                color: rgb(128, 128, 128);
                letter-spacing: 0;
                margin: 10px;
            }
        }

        .blockItem:hover {
            cursor: pointer;
            background-color: rgb(48, 150, 219);
        }

        .blockItem:hover .txt h5 {
            color: white;
        }

        .blockItem:hover .txt p {
            color: white;
        }
    }

    button {
        width: 300px;
        height: 80px;
        text-align: center;
        border: none;
        position: absolute;
        left: 810px;
        bottom: 50px;
        background-color: rgb(48, 150, 219);
        border-radius: 4px;
        line-height: 38px;
        font-weight: 500;
        font-size: 26px;
        letter-spacing: 0;
        color: #fff;
    }
}

/* 第三部分 */
.all3 {
    width: 100%;
    height: 896px;
    background-color: rgb(255, 255, 255);
    position: relative;
    box-sizing: border-box;

    // .title {
    //     width: 560px;
    //     height: 84px;

    //     img {
    //         width: 100%;
    //     }

    //     margin: 0 auto;
    //     padding-top: 80px;

    // }
    .title {
        width: 560px;
        height: 84px;
        position: absolute;
        top: 137px;
        left: 680px;
        text-align: center;

        h5 {
            width: 196px;
            height: 41px;
            line-height: 41px;
            font-weight: 700;
            font-size: 28px;
            text-align: center;
            color: rgb(20, 20, 20);
            margin: 0 auto;
        }

        p {
            height: 30px;
            font-weight: 400;
            font-size: 20px;
            margin-top: 20px;
            line-height: 30px;
            color: rgb(128, 128, 128);
        }
    }

    .container {
        width: 100%;
        height: 612px;
        position: absolute;
        bottom: 80px;
        left: 0;

        .showImg {
            width: 1118px;
            height: 612px;
            position: absolute;
            left: 401px;
            bottom: 0px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            img {
                width: 100%;
            }
        }


        .swiper-button-prev {
            left: 210px;
            top: 283px;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: white;
            border: none;
            position: absolute;
            color: transparent !important;

            img {
                width: 100%;
            }
        }

        .leftBtn {
            // color: transparent !important;
            color: #fff;
            background: transparent;
        }



        .swiper-button-next {
            top: 283px;

            right: 216px;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: white;
            border: none;
            position: absolute;
            color: transparent !important;

            img {
                width: 100%;
            }
        }

        .swiper-button-prev:after {
            display: none;
        }

        .swiper-button-next:after {
            display: none;
        }

    }

    .rightBtn {
        // color: transparent !important;
        color: #fff;
        background: transparent;
    }

    /* 轮播图片 */

}

/* 第四部分 */
.all4 {
    height: 755px;
    // border: 1px solid red;
    background-color: rgb(247, 250, 253);
    position: relative;
    width: 100%;

    .title {
        width: 560px;
        height: 84px;
        position: absolute;
        top: 80px;
        left: 680px;
        text-align: center;

        h5 {
            // width: 196px;
            height: 41px;
            line-height: 41px;
            font-weight: 700;
            font-size: 28px;
            text-align: center;
            color: rgb(20, 20, 20);
            margin: 0 auto;
        }

        p {
            height: 30px;
            font-weight: 400;
            font-size: 20px;
            // margin-top: 40px;
            line-height: 30px;
            text-align: center;
            margin: 0 auto;
            color: rgb(128, 128, 128);
        }
    }

    .block {
        position: absolute;
        left: 260px;

        width: 1400px;
        // border: 1px solid blue;
        display: flex;
        top: 195px;
        justify-content: space-around;

        .blockItem {
            width: 452px;
            height: 390px;
            // border: 1px solid yellow;
            position: relative;

            .img {
                width: 451px;
                height: 290px;

                img {
                    width: 100%;
                }
            }

            .txt {
                position: absolute;
                top: 246px;
                left: 10px;
                width: 432px;
                height: 144px;
                background-color: #fff;
                border-radius: 2px;

                .timer {
                    display: flex;
                    align-items: center;

                    position: absolute;
                    left: 28px;
                    top: 12px;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }

                    span {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgb(20, 20, 20);
                    }

                }

                h5 {
                    position: absolute;
                    top: 42px;
                    left: 28px;
                    font-size: 20px;
                    letter-spacing: 0;

                }

                .content {
                    position: absolute;
                    left: 28px;
                    top: 80px;
                    height: 48px;
                    text-overflow: ellipsis;
                    padding-right: 28px;
                    overflow: hidden;
                    white-space: wrap;
                    line-height: 24px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    letter-spacing: 0;
                    width: 357px;
                    height: 48px;
                    color: rgb(128, 128, 128);

                }
            }

            .timerItem {
                width: 405px;
                height: 82px;
                position: relative;
                border-bottom: 1px dashed rgb(128, 128, 128);

                h5 {
                    width: 405px;
                    height: 30px;
                    line-height: 30px;
                    font-size: 20px;
                    letter-spacing: 0;
                    color: rgb(48, 150, 219);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                p {
                    display: flex;
                    align-items: center;

                    position: absolute;
                    left: 3px;
                    bottom: 12px;

                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }

                    span {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: rgb(20, 20, 20);
                    }
                }
            }
        }

        .blockItem:nth-last-of-type(1) {
            padding: 23px 24px;
            background-color: rgb(242, 242, 242);
        }

        .blockItem:nth-last-of-type(1) main:nth-child(n+2) {
            margin-top: 40px;
        }

        .blockItem:hover .txt {
            background-color: rgb(48, 151, 219);
            color: white;
        }

        .blockItem:hover .txt span {
            color: white;
        }

        .blockItem:hover .content {
            color: white;
        }

        .blockItem:hover .timer {
            color: white;
        }
    }

    .more {
        width: 300px;
        height: 80px;
        position: absolute;
        left: 560px;
        bottom: -126px;
        background-color: none;
        border: 1px solid rgb(48, 150, 219);
        background-color: #fff;
        font-weight: 500;
        font-size: 26px;
        line-height: 38px;
        color: rgb(48, 150, 219);
    }
}

/* 第五部分 */
.all5 {
    width: 100%;
    height: 684px;
    // border: 1px solid red;
    background-color: rgb(255, 255, 255);
    position: relative;

    .box {
        width: 1400px;
        height: 524px;
        // border: 1px solid blue;
        position: absolute;
        left: 260px;
        top: 80px;

        .title {
            width: 560px;
            height: 84px;
            margin: 0 auto;
            // text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            h5 {
                width: 196px;
                height: 41px;
                line-height: 41px;
                font-weight: 700;
                font-size: 28px;
                text-align: center;
                color: rgb(20, 20, 20);
                margin: 0 auto;
            }

            p {
                height: 30px;
                font-weight: 400;
                font-size: 20px;
                // margin-top: 40px;
                line-height: 30px;
                color: rgb(128, 128, 128);
            }
        }

        .content {
            width: 1400px;
            height: 280px;
            display: flex;
            margin-top: 40px;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                width: 340px;
                height: 120px;
            }
        }

        .more {
            width: 300px;
            height: 80px;
            position: absolute;
            left: 560px;
            bottom: 0;
            background-color: none;
            border: 3px solid rgb(48, 150, 219);
            background-color: #fff;
            font-weight: 500;
            font-size: 26px;
            line-height: 38px;
            color: rgb(48, 150, 219);
        }
    }
}

/* 第六部分 */
.all6 {
    width: 100%;
    height: 530px;
    // border: 1px solid red;
    background-color: rgb(247, 250, 253);
    position: relative;

    .box {
        width: 1400px;
        // height: 524px;
        // border: 1px solid blue;
        position: absolute;
        left: 260px;
        top: 80px;

        .title {
            width: 560px;
            height: 84px;
            margin: 0 auto;
            // text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            h5 {
                width: 196px;
                height: 41px;
                line-height: 41px;
                font-weight: 700;
                font-size: 28px;
                text-align: center;
                color: rgb(20, 20, 20);
                margin: 0 auto;
            }

            p {
                height: 30px;
                font-weight: 400;
                font-size: 20px;
                // margin-top: 40px;
                line-height: 30px;
                color: rgb(128, 128, 128);
            }
        }

        .content {
            width: 1400px;
            height: 280px;
            display: flex;
            margin-top: 40px;
            justify-content: space-between;
            flex-wrap: wrap;

            .type {
                width: 1400px;
                height: 78px;
                display: flex;
                justify-content: space-between;

                li {
                    // width: 164px;
                    height: 78px;
                    // border: 1px solid green;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    img {
                        width: 60px;
                        height: 60px;
                    }

                    div {
                        margin-left: 20px;

                        h4 {
                            font-size: 26px;
                            line-height: 38px;
                            letter-spacing: 0;
                            color: rgb(20, 20, 20);
                        }

                        p {
                            font-size: 22px;
                            line-height: 33px;
                            letter-spacing: 0;
                        }
                    }
                }
            }

            .company {
                width: 1400px;
                height: 112px;
                display: flex;
                justify-content: space-between;

                li {
                    width: 328px;
                    height: 112px;
                    // border: 1px solid green;
                    font-size: 24px;
                    line-height: 31px;
                    color: rgb(20, 20, 20);
                    text-align: center;
                    line-height: 112px;
                    background-color: #fff;
                    // font-family: ;
                }
            }
        }

    }
}

/* 第七部分 */
.all7 {
    width: 100%;
    height: 400px;
    position: relative;
    background-color: rgb(48, 150, 219);

    .title {
        position: absolute;
        top: 60px;
        left: 258px;
        width: 279px;
        height: 100px;

        img {
            width: 100%;
        }

        padding: 14px 0;
        z-index: 11;
    }

    .content {
        position: absolute;
        top: 205px;
        left: 258px;
        width: 1123px;
        height: 90px;
        z-index: 11;

        img {
            width: 100%;
        }
    }

    .mengban {
        background: #3C95D8;
        width: 676px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        overflow: hidden;
    }
}

.timer_title {
    font-size: 20px;
    font-weight: 500;
    width: 80%;
    margin-top: 42px;
    margin-left: 26px;
    height: 26px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
